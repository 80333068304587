const profix = '/mnt';
const LoginApi = {
	// 登录
	login: {
		url: `${profix}/login`,
		method: 'post'
	},
	// 登出
	logout: {
		url: `${profix}/logout`,
		method: 'post'
	}
};
export default LoginApi;