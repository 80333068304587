import Vue from 'vue';
import Vuex from 'vuex';
import Main from './vuex/main.js';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		main: Main
	}
});