export default {
	data () {
		return {
			duration: 2000 // message 提示框 存在时长 单位：毫秒
		};
	},
	methods: {
		uploadImgHandle (res, successHandle) {
			if (res && res.code === 200) {
				successHandle();
			} else if (res.code === 10001) {
				window.location.href = window.location.origin + '/#/management/login';
			} else {
				this.$root.elMsg(res.msg, 'error');
			}
		}
	}
};