import mergeMixin from '@/routes/mixin';

const App = (resolve) => {
    import('./App.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
const Login = (resolve) => {
    import('./views/Login.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
const Mnt = (resolve) => {
    import('./views/Mnt.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
/* const MntRemind = (resolve) => {
    import('./views/MntRemind.vue').then((component) => {
        resolve(mergeMixin(component));
    });
}; */

import sysRoute from '@/routes/sys';

const routes = [
    {
        path: '/',
        name: 'home',
        component: App,
        redirect: '/management/login',
        children: [
            {
                path: '/management/login',
                component: Login,
                name: 'loginPage',
                meta: {
                    title: ''
                }
            }
        ]
    },
    {
        // 管理后台父容器
        path: '/mnt',
        component: Mnt,
        name: 'managementHome',
        meta: {
            title: '',
			navIndex: '1',
        },
        children: [
			...sysRoute
        ]
    },
    {
        path: '*',
        redirect: '/management/login'
    }
];

export default routes;