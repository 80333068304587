export default {
	methods: {
		// 路由跳转
		go (path, query, isLoading) {
            path = path.trim();// eslint-disable-line
			if (isLoading) {
				this.$store.commit('toggleFullPageLoading', true);
			}
			this.$router.push({ path: path, query: query || {} });
		},
		// 路由跳转
		replace (obj) {
			this.$store.commit('toggleFullPageLoading', true);
			this.$router.replace(obj);
		},
		/**
         * 全局通用的 消息弹框
         * @param {*} message
         * @param {*} type success、warning、info、error
         * @param {*} duration
         */
		elMsg (message = '操作成功', type = 'success', cb, duration = 2000) {
			this.$message({ message, type, duration, customClass: 'global-msg', onClose: cb });
		},
		elMsgHandleCb (cb, msg, type) {
			this.elMsg(msg, type, cb, 1000);
		}
	}
};