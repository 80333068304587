import mergeMixin from './mixin';

const Menus = (resolve) => {
	import('@/views/sys/menu.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const AddMenus = (resolve) => {
	import('@/views/sys/menu-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Roles = (resolve) => {
	import('@/views/sys/role.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const RoleAddOrUpdate = (resolve) => {
	import('@/views/sys/role-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const User = (resolve) => {
	import('@/views/sys/user.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const UserAddOrUpdate = (resolve) => {
	import('@/views/sys/user-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Patient = (resolve) => {
	import('@/views/sys/patient.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const PatientDetail = (resolve) => {
	import('@/views/sys/patient-detail.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const OutTax = (resolve) => {
	import('@/views/sys/outTax.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const taxOpt = (resolve) => {
	import('@/views/sys/outTax-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Excels = (resolve) => {
	import('@/views/sys/excels.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const ConsultationCloseReason = (resolve) => {
	import('@/views/sys/consultationCloseReason.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Policy = (resolve) => {
	import('@/views/policy/index.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const PolicyAddOrUpdate = (resolve) => {
	import('@/views/policy/add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Dept = (resolve) => {
	import('@/views/sys/dept.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const AddOrUpdateDept = (resolve) => {
	import('@/views/sys/dept-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Disease = (resolve) => {
	import('@/views/sys/disease.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Medicine = (resolve) => {
	import('@/views/sys/medicine.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const AddOrUpdateMedicine = (resolve) => {
	import('@/views/sys/medicine-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const AddOrUpdateDisease = (resolve) => {
	import('@/views/sys/disease-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Doctorsadvice = (resolve) => {
	import('@/views/sys/doctorsadvice.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Appraise = (resolve) => {
	import('@/views/appraise/index.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const DoctorApply = (resolve) => {
	import('@/views/sys/doctor-apply.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Doctor = (resolve) => {
	import('@/views/sys/doctor.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const AddOrUpdateDoctor = (resolve) => {
	import('@/views/sys/doctor-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const Banner = (resolve) => {
	import('@/views/sys/banner.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const AddOrUpdateBanner = (resolve) => {
	import('@/views/sys/banner-add-or-update.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const Consultation = (resolve) => {
	import('@/views/order/Consultation.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const Appointment = (resolve) => {
	import('@/views/order/Appointment.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const AppointmentDetail = (resolve) => {
	import('@/views/order/AppointmentDetail.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const Refund = (resolve) => {
	import('@/views/finance/refund.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const Cashout = (resolve) => {
	import('@/views/settlement/cashout.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const DoctorBill = (resolve) => {
	import('@/views/settlement/doctor-bill.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};
const DoctorBillDetail = (resolve) => {
	import('@/views/settlement/doctor-bill-detail.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const FinancialFlow = (resolve) => {
	import('@/views/settlement/finacial-flow.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const Reconciliation = (resolve) => {
	import('@/views/settlement/reconciliation.vue').then((component) => {
		resolve(mergeMixin(component));
	});
};

const routes = [
	{
		path: '/mnt/consultation',
		component: Consultation,
		name: 'Consultation',
		meta: {
			title: '',
			navIndex: '2-1', // 左侧导航栏的index
			navName: '问诊订单',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/appointment',
		component: Appointment,
		name: 'Appointment',
		meta: {
			title: '',
			navIndex: '2-2', // 左侧导航栏的index
			navName: '药品订单',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/appointmentDetail',
		component: AppointmentDetail,
		name: 'AppointmentDetail',
		meta: {
			title: '',
			navIndex: '2-2', // 左侧导航栏的index
			navName: '药品订单详情',
			breadLevel: 1
		}
	},
	// {
	// 	path: '/mnt/drugsOrder',
	// 	component: AppointmentOrder,
	// 	name: 'AppointmentOrder',
	// 	meta: {
	// 		title: '',
	// 		navIndex: '2-3', // 左侧导航栏的index
	// 		navName: '药品订单',
	// 		breadLevel: 1
	// 	}
	// },
	{
		path: '/mnt/policy',
		component: Policy,
		name: 'Policy',
		meta: {
			title: '',
			navIndex: '4-1', // 左侧导航栏的index
			navName: '协议管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdatePolicy',
		component: PolicyAddOrUpdate,
		name: 'PolicyAddOrUpdate',
		meta: {
			title: '',
			navIndex: '4-1', // 左侧导航栏的index
			navName: '',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/dept',
		component: Dept,
		name: 'Dept',
		meta: {
			title: '',
			navIndex: '4-3', // 左侧导航栏的index
			navName: '科室管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdateDept',
		component: AddOrUpdateDept,
		name: 'AddOrUpdateDept',
		meta: {
			title: '',
			navIndex: '4-3', // 左侧导航栏的index
			navName: '',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/disease',
		component: Disease,
		name: 'Disease',
		meta: {
			title: '',
			navIndex: '4-4', // 左侧导航栏的index
			navName: '疾病管理',
			breadLevel: 1
		}
	},
	// 药品详情/编辑
	{
		path: '/mnt/addOrUpdateMedicine',
		component: AddOrUpdateMedicine,
		name: 'AddOrUpdateMedicine,',
		meta: {
			title: '',
			navIndex: '',
			navName: '药品管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdateDisease',
		component: AddOrUpdateDisease,
		name: 'AddOrUpdateDisease',
		meta: {
			title: '',
			navIndex: '4-4',
			navName: '',
			breadLevel: 1
		}
	},
	// 药品管理
	{
		path: '/mnt/kl-medicine',
		component: Medicine,
		name: 'KlMedicine',
		meta: {
			title: '',
			navIndex: '4-5',
			navName: '颗粒药房药品管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/yp-medicine',
		component: Medicine,
		name: 'YpMedicine',
		meta: {
			title: '',
			navIndex: '4-6',
			navName: '饮片药房药品管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/doctorsadvice',
		component: Doctorsadvice,
		name: 'Doctorsadvice',
		meta: {
			title: '',
			navIndex: '4-7',
			navName: '医嘱管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/appraise',
		component: Appraise,
		name: 'Appraise',
		meta: {
			title: '',
			navIndex: '4-8',
			navName: '评价审核',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/doctor-apply',
		component: DoctorApply,
		name: 'DoctorApply',
		meta: {
			title: '',
			navIndex: '5-1', // 左侧导航栏的index
			navName: '医生管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/doctor',
		component: Doctor,
		name: 'Doctor',
		meta: {
			title: '',
			navIndex: '5-2', // 左侧导航栏的index
			navName: '医生管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdateDoctor',
		component: AddOrUpdateDoctor,
		name: 'AddOrUpdateDoctor',
		meta: {
			title: '',
			navIndex: '5-1', // 左侧导航栏的index
			navName: '',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/menus',
		component: Menus,
		name: 'Menus',
		meta: {
			title: '',
			navIndex: '6-1', // 左侧导航栏的index
			navName: '菜单管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addMenus',
		component: AddMenus,
		name: 'AddMenus',
		meta: {
			title: '',
			navIndex: '6-1', // 左侧导航栏的index
			navName: '新增菜单',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/roles',
		component: Roles,
		name: 'Roles',
		meta: {
			title: '',
			navIndex: '6-2', // 左侧导航栏的index
			navName: '角色管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdateRole',
		component: RoleAddOrUpdate,
		name: 'RoleAddOrUpdate',
		meta: {
			title: '',
			navIndex: '6-2', // 左侧导航栏的index
			navName: '',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/user',
		component: User,
		name: 'User',
		meta: {
			title: '',
			navIndex: '6-3', // 左侧导航栏的index
			navName: '用户管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdateUser',
		component: UserAddOrUpdate,
		name: 'UserAddOrUpdate',
		meta: {
			title: '创建用户',
			navIndex: '6-3', // 左侧导航栏的index
			navName: '创建用户',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/banner',
		component: Banner,
		name: 'Banner',
		meta: {
			title: '',
			navIndex: '6-4', // 左侧导航栏的index
			navName: 'banner管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/addOrUpdateBanner',
		component: AddOrUpdateBanner,
		name: 'AddOrUpdateBanner',
		meta: {
			title: '',
			navIndex: '6-4', // 左侧导航栏的index
			navName: '',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/patient',
		component: Patient,
		name: 'Patient',
		meta: {
			title: '',
			navIndex: '6-5', // 左侧导航栏的index
			navName: '患者账户',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/patient-detail',
		component: PatientDetail,
		name: 'PatientDetail',
		meta: {
			title: '',
			navIndex: '6-5', // 左侧导航栏的index
			navName: '就诊人',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/outTax',
		component: OutTax,
		name: 'OutTax',
		meta: {
			title: '',
			navIndex: '6-6', // 左侧导航栏的index
			navName: '系统税费配置',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/taxOpt',
		component: taxOpt,
		name: 'taxOpt',
		meta: {
			title: '',
			navIndex: '6-6', // 左侧导航栏的index
			navName: '系统税费配置',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/excels',
		component: Excels,
		name: 'excels',
		meta: {
			title: '',
			navIndex: '6-7', // 左侧导航栏的index
			navName: 'excel下载',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/consultationCloseReason',
		component: ConsultationCloseReason,
		name: 'ConsultationCloseReason',
		meta: {
			title: '',
			navIndex: '6-8', // 左侧导航栏的index
			navName: '退诊原因管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/refund',
		component: Refund,
		name: 'Refund',
		meta: {
			title: '',
			navIndex: '7-1', // 左侧导航栏的index
			navName: '退款管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/cashout',
		component: Cashout,
		name: 'Cashout',
		meta: {
			title: '',
			navIndex: '8-1', // 左侧导航栏的index
			navName: '医生提现管理',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/doctor-bill',
		component: DoctorBill,
		name: 'DoctorBill',
		meta: {
			title: '',
			navIndex: '8-2', // 左侧导航栏的index
			navName: '医生结算单',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/doctor-bill-detail',
		component: DoctorBillDetail,
		name: 'DoctorBillDetail',
		meta: {
			title: '',
			navIndex: '8-2', // 左侧导航栏的index
			navName: '医生结算详情',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/financial-flow',
		component: FinancialFlow,
		name: 'FinancialFlow',
		meta: {
			title: '',
			navIndex: '7-2', // 左侧导航栏的index
			navName: '财务明细',
			breadLevel: 1
		}
	},
	{
		path: '/mnt/reconciliation',
		component: Reconciliation,
		name: 'Reconciliation',
		meta: {
			title: '',
			navIndex: '7-3', // 左侧导航栏的index
			navName: '对账中心',
			breadLevel: 1
		}
	}
];

export default routes;
