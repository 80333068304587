import Vue from 'vue';
import App from './App.vue';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/common.less';
import router from './router';
import store from './store';
// import './plugins/element.js'
import vueInit from './mixins/vue-init.js';
import Ajax from './request/Ajax.js';

import {
	Button,
	Form,
	FormItem,
	Message,
	MessageBox,
	Loading,
	DatePicker,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Container,
	Menu,
	MenuItem,
	MenuItemGroup,
	Submenu,
	Select,
	Option,
	Switch,
	Dialog,
	Header,
	Table,
	TableColumn,
	Aside,
	Main,
	Input,
	InputNumber,
	Transfer,
	Progress,
	Divider,
	Breadcrumb,
	Popover,
	BreadcrumbItem,
	Checkbox,
	Row,
	Col,
	Tree,
	Radio,
	RadioGroup,
	RadioButton,
	Tag,
	Pagination,
	Upload,
	Tabs,
	TabPane,
	Steps,
	Step,
	Popconfirm,
	Badge
} from 'element-ui';

Vue.config.productionTip = false;
Vue.use(Ajax);
Vue.use(Button);
Vue.use(Popconfirm);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Main);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(MenuItemGroup);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Select);
Vue.use(Option);
Vue.use(Switch);
Vue.use(Dialog);
Vue.use(Header);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Aside);
Vue.use(Container);
Vue.use(Transfer);
Vue.use(Progress);
Vue.use(Divider);
Vue.use(Breadcrumb);
Vue.use(Popover);
Vue.use(BreadcrumbItem);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tree);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Tag);
Vue.use(Pagination);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Upload);
Vue.use(Badge);
Vue.prototype.$message = Message;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.use(Loading.directive);

const vue = new Vue({
	router,
	store,
	mixins: [vueInit],
	render: h => h(App)
}).$mount('#app');
window.__vue = vue;