import axios from 'axios';
import { Message } from 'element-ui';

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
	// withCredentials: true,
	timeout: 3500000 // request timeout
});

const whiteList = ['/mnt/login'];
// 拦截器
service.interceptors.request.use(config => {
	if (whiteList.indexOf(config.url) === -1) {
		let loginInfo = window.localStorage.getItem('smt_ui');
		if (!loginInfo || loginInfo === 'undefined') {
			window.__vue.replace('/management/login');
			return null;
		} else {
			loginInfo = JSON.parse(loginInfo);
			if (!loginInfo || !loginInfo.token) {
				window.__vue.replace('/management/login');
				return false;
			} else {
				config.headers.token = loginInfo.token;
				return config;
			}
		}
	} else {
		return config;
	}
}, error => {
	// eslint-disable-next-line no-console
	console.log(error); // for debug
	Promise.reject(error);
});

// respone interceptor
service.interceptors.response.use(
	response => {
		if (response && response.data) {
			if (response.data.code === 200) {
				if (response.data.data && response.data.data.items === null) {
					response.data.data.items = [];
				}
				return response.data.data;
			} else if (response.data.code === 10001) {
				window.location.href = window.location.origin + '/#/management/login';
			} else {
				Message({
					message: response.data.msg,
					type: 'error',
					duration: 2 * 1000,
					customClass: 'global-msg'
				});
				return null;
			}
		} else {
			Message({
				message: response.data.msg,
				type: 'error',
				duration: 2 * 1000,
				customClass: 'global-msg'
			});
		}
	},
	error => {
		let errMsg = '';
		if (error.response && error.response.status === 404) {
			errMsg = '接口未找到';
		} else if (error.response && error.response.data.msg) {
			errMsg = error.response.data.msg;
		} else {
			return false;
		}
		Message({
			message: errMsg,
			type: 'error',
			duration: 5 * 1000,
			customClass: 'global-msg'
		});
		return false;
	});
export default service;