<template>
  <div class="main">
    <vue-loading></vue-loading>
    <router-view></router-view>
  </div>
</template>
<script>
import vueLoading from './views/common/loading';
export default {
	components: {
		vueLoading
	}
};
</script>

<style lang="less" scoped>
  .main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>

<style>
	#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
	}
	.global-msg {
	z-index: 9999 !important;
	}
	.global-form-input {
		width: 200px !important;
	}
	.global-dialog-footer {
		width: 50%;
		margin: 0 auto;
		display: flex;
		justify-content: space-around;
	}
</style>