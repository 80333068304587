const Main = {
	state: {
		// 是否展示全屏loading
		isPageLoading: false,
		adminInfo: {}, // 管理员信息
		breadcrumbs: [] // 面包屑导航列表
	},
	mutations: {
		// 展示/隐藏全屏loading
		toggleFullPageLoading (state, loading = false) {
			state.isPageLoading = loading;
		},
		setAdminInfo (state, admin) {
			state.adminInfo = admin;
		},
		setBreadcrumbs (state, data) {
			state.breadcrumbs = data;
		}
	},
	getters: {
		isPageLoadingGetter: state => {
			return state.isPageLoading;
		}
	}
};

export default Main;