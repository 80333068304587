import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes.js';

Vue.use(Router);
const router = new Router({
	mode: 'hash',
	routes
});
router.afterEach((to) => {
	setTimeout(() => {
		const breadLevel = to.meta.breadLevel;
		let breadcrumbs = router.app.$store.state.main.breadcrumbs;
		if (breadLevel === 1) {
			breadcrumbs = [{
				breadcrumbName: to.meta.navName,
				path: to.path
			}];
		} else {
			breadcrumbs.splice(breadLevel - 1, 1);
			breadcrumbs.push({
				breadcrumbName: to.meta.navName,
				path: to.path
			});
		}
		router.app.$store.commit('setBreadcrumbs', breadcrumbs);
	});
});

export default router;