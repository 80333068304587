const profix = '/mnt';
const MenuApi = {
	passwordModify: {
		url: `${profix}/mnt-user/updatePwd`,
		method: 'post'
	},
	menuList: {
		url: `${profix}/menu/list`,
		method: 'get'
	},
	menuSelect: {
		url: `${profix}/menu/select`,
		method: 'get'
	},
	menuAdd: {
		url: `${profix}/menu/add`,
		method: 'post'
	},
	menuDetail: {
		url: `${profix}/menu/detail`,
		method: 'get'
	},
	roleList: {
		url: `${profix}/role/list`,
		method: 'get'
	},
	roleDetail: {
		url: `${profix}/role/detail`,
		method: 'get'
	},
	roleAddOrUpdate: {
		url: `${profix}/role/addOrUpdate`,
		method: 'post'
	},
	roleDelete: {
		url: `${profix}/role/del`,
		method: 'post'
	},
	deptAddOrUpdate: {
		url: `${profix}/dept/addOrUpdate`,
		method: 'post'
	},
	deptList: {
		url: `${profix}/dept/list`,
		method: 'get'
	},
	// 删除科室
	deptDelete: {
		url: `${profix}/dept/del`,
		method: 'post'
	},
	deptDetail: {
		url: `${profix}/dept/detail`,
		method: 'get'
	},
	diseaseList: {
		url: `${profix}/disease/list`,
		method: 'get'
	},
	diseaseDel: {
		url: `${profix}/disease/del`,
		method: 'post'
	},
	diseaseAddOrUpdate: {
		url: `${profix}/disease/addOrUpdate`,
		method: 'post'
	},
	diseaseRecommendUpdate: {
		url: `${profix}/disease/recommendUpdate`,
		method: 'post'
	},
	diseaseDetail: {
		url: `${profix}/disease/detail`,
		method: 'get'
	},
	doctorList: {
		url: `${profix}/doctor/list`,
		method: 'get'
	},
	doctorApplyList: {
		url: `${profix}/doctor-apply/list`,
		method: 'get'
	},
	doctorApplyDelete: {
		url: `${profix}/doctor-apply/del`,
		method: 'post'
	},
	titleList: {
		url: `${profix}/title/list`,
		method: 'get'
	},
	doctorServiceStatusUpdate: {
		url: `${profix}/doctor/serviceStatusUpdate`,
		method: 'post'
	},
	// 医生审核
	doctorDoApp: {
		url: `${profix}/doctor/doApp`,
		method: 'post'
	},
	// 新增/修改 医生
	doctorEditApply: {
		url: `${profix}/doctor/editApply`,
		method: 'post'
	},
	// 医生审核详情
	doctorDetail: {
		url: `${profix}/doctor/detail`,
		method: 'get'
	},
	// 查询banner列表
	bannerList: {
		url: `${profix}/banner/list`,
		method: 'get'
	},
	// 新增/修改 banner
	bannerAddOrUpdate: {
		url: `${profix}/banner/addOrUpdate`,
		method: 'post'
	},
	// banner详情
	bannerDetail: {
		url: `${profix}/banner/detail`,
		method: 'get'
	},
	// 问诊单列表
	consultationList: {
		url: `${profix}/consultation/list`,
		method: 'get'
	},
	// 药品单列表
	appointmentList: {
		url: `${profix}/appointment/list`,
		method: 'get'
	},
	// 药品单详情
	appointmentDetail: {
		url: `${profix}/appointment/detail`,
		method: 'get'
	},
	// 物流信息详情
	expressDetail: {
		url: `${profix}/express/query`,
		method: 'get'
	},
	// 药品单取消
	appointmentCancel: {
		url: `${profix}/appointment/cancel`,
		method: 'post'
	},
	// 创建退款申请记录
	refundRecordAdd: {
		url: `${profix}/refundRecord/add`,
		method: 'post'
	},
	// 查询退款申请记录
	refundRecordList: {
		url: `${profix}/refundRecord/list`,
		method: 'get'
	},
	// 微信进行退款
	refundExecute: {
		url: '/v1/wechat-pay/refund-execute',
		method: 'post'
	},
	// 问诊单进行分成
	divideIntoApi: {
		url: `${profix}/divideInto`,
		method: 'post'
	},
	// 药品出库
	appointmentStockout: {
		url: `${profix}/appointment/stockout`,
		method: 'post'
	},
	// 带出库的预约单数量
	appointmentWaitStockout: {
		url: `${profix}/appointment/waitStockout`,
		method: 'get'
	},
	// 中药列表
	medicineList: {
		url: `${profix}/medicine/list`,
		method: 'get'
	},
	// 中药列表
	medicineDetail: {
		url: `${profix}/medicine/detail`,
		method: 'get'
	},
	// 添加/更新 中药
	medicineAddOrUpdate: {
		url: `${profix}/medicine/addOrUpdate`,
		method: 'post'
	},
	// 修改中药是否上架
	changeGroundingStatus: {
		url: `${profix}/medicine/changeGrounding`,
		method: 'post'
	},
	// 协议列表
	policyList: {
		url: `${profix}/policy/list`,
		method: 'get'
	},
	// 添加协议
	policyAdd: {
		url: `${profix}/policy/add`,
		method: 'post'
	},
	// 协议详情
	policyDetail: {
		url: `${profix}/policy/detail`,
		method: 'get'
	},
	// 协议修改
	policyUpdate: {
		url: `${profix}/policy/update`,
		method: 'post'
	},
	// 协议状态修改
	policyChangeStatus: {
		url: `${profix}/policy/changeStatus`,
		method: 'post'
	},
	// 获取OSS token
	getOSSToken: {
		url: '/v1/alioss/STSToken',
		method: 'get'
	},
	// banner状态修改
	bannerChangeStatus: {
		url: `${profix}/banner/statusChange`,
		method: 'post'
	},
	// banner删除
	bannerDelete: {
		url: `${profix}/banner/del`,
		method: 'post'
	},
	// 管理后台用户列表
	userList: {
		url: `${profix}/mnt-user/list`,
		method: 'get'
	},
	// 管理后台用户添加
	userAdd: {
		url: `${profix}/mnt-user/add`,
		method: 'post'
	},
	// 管理后台用户删除
	userDelete: {
		url: `${profix}/mnt-user/del`,
		method: 'post'
	},
	// 管理后台患者账户列表
	patientAccountList: {
		url: `${profix}/patient-account/list`,
		method: 'get'
	},
	// 管理后台患者账户下就诊人列表
	patientListById: {
		url: `${profix}/patient/list`,
		method: 'get'
	},
	// 财务流水
	financialFlow: {
		url: `${profix}/financial/flow`,
		method: 'get'
	},
	// 提现申请列表
	cashoutList: {
		url: `${profix}/cashout/list`,
		method: 'get'
	},
	// 提现审核
	cashoutAudit: {
		url: `${profix}/cashout/confirm`,
		method: 'post'
	},
	// 查询系统总收入和应结算
	incomeOrCashOut: {
		url: `${profix}/incomeOrCashOut`,
		method: 'get'
	},
	// 查询系统总收入和应结算明细
	incomeOrCashOutDetail: {
		url: `${profix}/incomeOrCashOut/detail`,
		method: 'get'
	},
	// 查询医生结算总览
	cashOutDoctorList: {
		url: `${profix}/cashout-doctor/list`,
		method: 'get'
	},
	// 查询医生财务明细
	cashOutDoctorDetailList: {
		url: `${profix}/cashout-doctor/detail`,
		method: 'get'
	},
	// 获取用户可见菜单栏
	getMenus: {
		url: `${profix}/user/menu/list`,
		method: 'get'
	},
	// 获取医生提现代扣税配置列表
	getCashoutTax: {
		url: `${profix}/cashouttax/list`,
		method: 'get'
	},
	// 获取医生提现代扣税配置列表
	cashoutTaxOpt: {
		url: `${profix}/cashouttax/opt`,
		method: 'post'
	},
	// 删除医生提现代扣税配置列表
	cashoutTaxDel: {
		url: `${profix}/cashouttax/del`,
		method: 'post'
	},
	// 获取医嘱列表
	doctorsadviceList: {
		url: `${profix}/doctorsadvice/list`,
		method: 'get'
	},
	// 获取医嘱类型列表
	doctorsadviceTypelist: {
		url: `${profix}/doctorsadvice/typeList`,
		method: 'get'
	},
	// 新增/编辑医嘱
	doctorsadviceOpt: {
		url: `${profix}/doctorsadvice/opt`,
		method: 'post'
	},
	// 修改医嘱启用状态
	doctorsadviceStatusChange: {
		url: `${profix}/doctorsadvice/statusChange`,
		method: 'post'
	},
	// 创建导出excel的任务
	exportExcelTask: {
		url: `${profix}/exceltask`,
		method: 'post'
	},
	// excel 列表
	excelList: {
		url: `${profix}/excel/list`,
		method: 'get'
	},
	// 退诊原因 列表
	consultationCloseReasonList: {
		url: `${profix}/consultationCloseReason/list`,
		method: 'get'
	},
	// 删除退诊原因
	consultationCloseReasonDelete: {
		url: `${profix}/consultationCloseReason/del`,
		method: 'post'
	},
	// 新增退诊原因
	consultationCloseReasonAdd: {
		url: `${profix}/consultationCloseReason/add`,
		method: 'post'
	},
	// 评价 列表
	appraiseList: {
		url: `${profix}/appraise/list`,
		method: 'get'
	},
	// 评价 审核
	appraiseApply: {
		url: `${profix}/appraise/apply`,
		method: 'post'
	}
};
export default MenuApi;