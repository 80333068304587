<template>
    <div
        class="loading"
        v-loading.fullscreen.lock="isPageLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.76)"
    ></div>
</template>

<script>
export default {
	data () {
		return {
			isPageLoading: false
		};
	},
	watch: {
		'$store.state.main.isPageLoading' (val) {
			this.isPageLoading = val;
		}
	}
};
</script>
<style lang="less" scoped>
    .loading {
        font-size: 50px;
    }
</style>